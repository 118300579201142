import React, { useEffect, useState } from "react";
import ImageWithBasePath from "../../core/img/imagewithbasebath";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { Link } from "react-router-dom";
import {
  ChevronUp,
  PlusCircle,
  RotateCcw,
  Send,
  Calendar,
  Eye,
} from "feather-icons-react/build/IconComponents";
import { useDispatch, useSelector } from "react-redux";
import { setheadertoggle } from "../../redux/toggle";
import { fetchProduct, fetchUnit } from "../../redux/userSetting";
import AdminProductTable from "../../components/adminsitration/admintables/AdminProductTable";
import AddProduction from "../../components/transactions/production/AddProduction";
import { getproductionbybranch } from "../../services/StockApiServices";
import { getProductBOQlist } from "../../services/MasterApiServices";
import { DatePicker, Select } from "antd";
import {
  printReport,
  exportExcel,
  exportPDF,
} from "../../core/utils/reportUtils";
import { renderTooltip } from "../../redux/helper";
import dayjs from "dayjs";

const Production = () => {
  const dispatch = useDispatch();
  const [searchText, setSearchText] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const [modalMode, setModalMode] = useState("Add");
  const [productionData, setproductionData] = useState([]);
  const [ProductBOQData, setProductBOQData] = useState([]);
  const [BOQFormatedProduct, setBOQFormatedProduct] = useState([]);
  const [updateData, setUpdateData] = useState();
  const [fromDate, setFromDate] = useState(dayjs());
  const [toDate, setToDate] = useState(dayjs());
  const { branch } = useSelector((state) => state.branchlist);
  const { userId } = useSelector((state) => state.userauth);
  const { unitList } = useSelector((state) => state.usersetting);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [filteredData, setFilteredData] = useState([]);
  const { menuItems } = useSelector((state) => state.amc);
  const [permit, setpermit] = useState();
  const fromDateRef = useState(fromDate);
  const toDateRef = useState(toDate);
  useEffect(() => {
    setpermit(
      menuItems?.menuPermissions?.find((e) => e.menu.name == "Production")
    );
  }, []);
  useEffect(() => {
    handleProductionList();
    handleProductBoqList();
    dispatch(fetchUnit());
    dispatch(fetchProduct(branch));

  }, [branch]);
  const normalizeDate = (date) => {
    const d = new Date(date);
    d.setHours(0, 0, 0, 0); // Reset time to 00:00:00
    return d;
  };
  const handleProductionList = async () => {
    try {
      const response = await getproductionbybranch(branch);
      console.log(response.data);
      setproductionData(response.data);
      const data = response.data;
      const currentDateData = data?.filter((entry) => {
        console.log("fromDate:", fromDate);
        console.log("toDate:", toDate);

        const fromDateNormalized = fromDate ? normalizeDate(fromDate) : null;
        const toDateNormalized = toDate ? normalizeDate(toDate) : null;
        const entryDateNormalized = normalizeDate(entry.date);
        
        const fromDateMatch = !fromDateNormalized || entryDateNormalized >= fromDateNormalized;
        const toDateMatch = !toDateNormalized || entryDateNormalized <= toDateNormalized;
console.log(fromDateMatch,toDateMatch);

        return fromDateMatch && toDateMatch;
      });
      setFilteredData(currentDateData);
    } catch (error) {
      console.log(error);
    }
  };

  const { togglehead } = useSelector((state) => state.headerToggle);

  const handletogglehead = () => {
    dispatch(setheadertoggle(!togglehead));
  };
  const handleSearch = (e) => {
    setSearchText(e.target.value);
  };
console.log(productionData,"lll");

  function handleFilter() {
    console.log("Search Text:", searchText);
    console.log("From Date:", fromDate, "To Date:", toDate);
    console.log("Selected Product:", selectedProduct);
    const filteredData = productionData?.filter((entry) => {
      console.log("Current Entry:", entry);

      const productMatch =!selectedProduct || entry?.product?.guid == selectedProduct;
      const fromDateNormalized = fromDate ? normalizeDate(fromDate) : null;
      const toDateNormalized = toDate ? normalizeDate(toDate) : null;
      const entryDateNormalized = normalizeDate(entry.date);
      
      const fromDateMatch = !fromDateNormalized || entryDateNormalized >= fromDateNormalized;
      const toDateMatch = !toDateNormalized || entryDateNormalized <= toDateNormalized;

      const searchTextMatch =
        entry.product?.name?.toLowerCase().includes(searchText?.toLowerCase()) ||
        entry.entryno?.toLowerCase().includes(searchText?.toLowerCase());
console.log(productMatch , fromDateMatch , toDateMatch , searchTextMatch);

      return productMatch && fromDateMatch && toDateMatch && searchTextMatch;
    });

    console.log("filtered Data:", filteredData);
    setFilteredData(filteredData);
  }
  const modifiedList = productionData?.filter(
    (value) =>
      value.product?.name?.toLowerCase().includes(searchText?.toLowerCase()) ||
      value.entryno?.toLowerCase().includes(searchText?.toLowerCase())
  );
  const columns = [
    {
      title: "Date",
      dataIndex: "date",
      render: (text) => {
        const formatDate = (dateString) => {
          // If dateString is valid, parse and format
          if (dateString) {
            const date = new Date(dateString); // Parse ISO date string
            if (!isNaN(date)) {
              return date.toLocaleDateString("en-GB", {
                day: "2-digit",
                month: "short",
                year: "numeric",
              });
            }
          }
          // If invalid, return a fallback
          return "Invalid Date";
        };

        return <span>{formatDate(text)}</span>; // `text` contains the date
      },
    },
    {
      title: "Entry No.",
      dataIndex: "entryno",
    },
    {
      title: "Product",
      dataIndex: "products",
      render: (_, record) => record?.product?.name,
    },
    {
      title: "Quantity",
      dataIndex: "quanitity",
      sorter: (a, b) => a.quanitity - b.quanitity,
    },
    {
      title: "Unit",
      dataIndex: "units",
      render: (_, record) =>
        unitList?.find((e) => e.guid == record?.unit?.guid)?.name,
    },
    ...(permit?.edit || permit?.deletion
      ? [
          {
            title: "Actions",
            dataIndex: "actions",
            key: "actions",
            render: (_, obj) => {
              const prefix = obj?.entryno?.slice(0, 2); // Extracting the prefix
              
              return (
                <div className="action-table-data">
                  <div className="edit-delete-action">
                    {permit?.edit && prefix === 'WP'&& (
                      <Link
                        className="me-2 p-2"
                        to="#"
                        onClick={() => handleEditClick(obj.guid, 'Edit')}
                      >
                        <i data-feather="edit" className="feather-edit"></i>
                      </Link>
                    )}
                    
                      <Link
                        className="me-2 p-2"
                        to="#"
                        onClick={() => handleEditClick(obj.guid, 'View')}
                      >
                        <Eye className="feather-view" />
                      </Link>
                 
                  </div>
                </div>
              );
            }
            
          },
        ]
      : []),
  ];
  const handleEditClick = async (id,action) => {
    setModalMode(action); // Set the modal mode to 'edit'
    setModalOpen(true); // Open the modal
    const data = productionData?.find((e) => id == e.guid);
    setUpdateData(data);
  };

  const handleProductBoqList = async () => {
    try {
      const response = await getProductBOQlist(branch);
      console.log(response);

      if (Array.isArray(response)) {
        setProductBOQData(response);
        const product = response?.map((e) => ({
          value: e.guid,
          label: e.name,
        }));
        setBOQFormatedProduct(product);
      } else {
        setProductBOQData([]);
      }
    } catch (error) {
      console.log(error?.response?.data?.Message);
      setProductBOQData([]);
    }
  };

  const getActiveTabData = () => {
    let fieldMapping = {};

    // Function to create the field mapping between column titles and dataIndex values
    const createfieldMapping = (columns) => {
      const mappings = {};
      columns.forEach((col) => {
        mappings[col.title] = col.dataIndex; // Map title to dataIndex
      });
      return mappings;
    };

    // Generate the field mapping for the current columns
    fieldMapping = createfieldMapping(columns);

    console.log("fieldMapping of first table:", fieldMapping);

    // Return the filtered data, columns, and field mapping
    return { data: filteredData, columns: columns, fieldMapping };
  };

  function handleReset() {
    setFromDate(dayjs());
    setToDate(dayjs());
    fromDateRef.current = dayjs();
    toDateRef.current = dayjs();
    setSelectedProduct(null);
    setSearchText("");
    handleProductionList();
    // setFilteredData(productionData);
  }
  const handleFromDateChange = (date) => {
    setFromDate(date);
    setToDate(null);
    console.log(date, "from date");
  };

  const handleToDateChange = (date) => {
    setToDate(date);
    console.log(date, "to date");
  };

  return (
    <div>
      <div className="page-wrapper">
        <div className="content">
          <div className="page-header transfer">
            <div className="add-item d-flex">
              <div className="page-title">
                <h4>Production</h4>
              </div>
            </div>
            <ul className="table-top-head">
              <li>
                <OverlayTrigger placement="top" overlay={renderTooltip("PDF")}>
                  <Link onClick={() => exportPDF(getActiveTabData,"Production")}>
                    <ImageWithBasePath
                      src="assets/img/icons/pdf.svg"
                      alt="img"
                    />
                  </Link>
                </OverlayTrigger>
              </li>
              <li>
                <OverlayTrigger
                  placement="top"
                  overlay={renderTooltip("Excel")}
                >
                  <Link onClick={() => exportExcel(getActiveTabData,"Production")}>
                    <ImageWithBasePath
                      src="assets/img/icons/excel.svg"
                      alt="img"
                    />
                  </Link>
                </OverlayTrigger>
              </li>
              <li>
                <OverlayTrigger
                  placement="top"
                  overlay={renderTooltip("Print")}
                >
                  <Link onClick={() => printReport(getActiveTabData,"Production")}>
                    <i data-feather="printer" className="feather-printer" />
                  </Link>
                </OverlayTrigger>
              </li>
              <li>
                <OverlayTrigger
                  placement="top"
                  overlay={renderTooltip("Refresh")}
                >
                  <Link
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    onClick={handleProductionList}
                  >
                    <RotateCcw />
                  </Link>
                </OverlayTrigger>
              </li>
              <li>
                <OverlayTrigger
                  placement="top"
                  overlay={renderTooltip("Collapse")}
                >
                  <Link
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    id="collapse-header"
                    className={togglehead ? "active" : ""}
                    onClick={handletogglehead}
                  >
                    <ChevronUp />
                  </Link>
                </OverlayTrigger>
              </li>
            </ul>
            <div className="d-flex purchase-pg-btn">
              <div className="page-btn">
                <Link
                  to="#"
                  className="btn btn-added"
                  // data-bs-toggle="modal"
                  // data-bs-target="#add-production"
                  onClick={() => {
                    setModalMode("Add"); // Set the modal mode to 'add'
                    setUpdateData();
                    setModalOpen(true);
                  }}
                >
                  <PlusCircle className="me-2" />
                  Add New
                </Link>
              </div>
            </div>
          </div>

          <div className="card table-list-card">
            <div className="card-body">
              <div className="table-top">
                <div className="search-set">
                  <div className="search-input">
                    <input
                      type="text"
                      placeholder="Search"
                      className="form-control form-control-sm formsearch"
                      aria-controls="DataTables_Table_0"
                      value={searchText}
                      onChange={handleSearch}
                    />
                    <Link to className="btn btn-searchset btn-sm rounded-2">
                      <i data-feather="search" className="feather-search" />
                    </Link>
                  </div>
                </div>

                <div className="d-flex justify-content-end">
                  <div className="input-blocks me-2">
                    <button
                      className="btn btn-filters btn-sm rounded-2"
                      onClick={handleFilter}
                    >
                      <i data-feather="search" className="feather-search" />{" "}
                      Search
                    </button>
                  </div>

                  <div className="input-blocks">
                    <button
                      className="btn btn-reset rounded-2 "
                      onClick={handleReset}
                    >
                      <i
                        data-feather="refresh-cw"
                        className="feather-refresh-cw"
                      />{" "}
                      Reset
                    </button>
                  </div>
                </div>
              </div>

              <div
                className=" visible"
                id="filter_inputs"
                style={{ display: "block" }}
              >
                <div className="card-body">
                  <div className="row">
                    {/* From Date Filter */}
                    <div className="col-lg-2 col-sm-6 col-12">
                      <div className="input-blocks">
                        <label>From Date</label>
                        <div className="input-groupicon ">
                          <Calendar className="info-img" />
                          <DatePicker
                            className="datetimepicker"
                            id="from-date"
                            placeholderText="From Date"
                            value={fromDate}
                            onChange={handleFromDateChange}
                            startDate={fromDate}
                            selectsStart
                            dateFormat="dd/MM/yyyy"
                            style={{ height: "38px", width: "100%" }} // Inline styles for height and width
                          />
                        </div>
                      </div>
                    </div>

                    {/* To Date Filter */}
                    <div className="col-lg-2 col-sm-6 col-12">
                      <div className="input-blocks">
                        <label>To Date</label>
                        <div className="input-groupicon ">
                          <Calendar className="info-img" />
                          <DatePicker
                            id="to-date"
                            placeholderText="To Date"
                            value={toDate}
                            onChange={handleToDateChange}
                            minDate={fromDate}
                            startDate={fromDate}
                            selectsEnd
                            disabled={!fromDate}
                            dateFormat="dd/MM/yyyy"
                            style={{ height: "38px", width: "100%" }} // Inline styles for height and width
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-3 col-sm-6 col-12">
                      <div className="input-blocks">
                        <label>Product</label>

                        <div className="input-groupicon ">
                          <Select
                            className="img-select"
                            options={BOQFormatedProduct}
                            value={selectedProduct}
                            onChange={setSelectedProduct}
                            classNamePrefix="react-select"
                            placeholder="Products"
                            style={{ height: "38px", width: "100%" }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="table-responsive product-list">
                <AdminProductTable
                  columns={columns}
                  dataSource={filteredData}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      {modalOpen && (
        <AddProduction
          productionData={productionData}
          ProductBOQData={ProductBOQData}
          mode={modalMode}
          updateData={updateData}
          handleClose={() => setModalOpen(false)}
          onsuccess={handleProductionList}
        />
      )}
    </div>
  );
};

export default Production;
