import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  RotateCcw,
  ChevronUp,
  PlusCircle,
  RefreshCw,
  Calendar,
} from "feather-icons-react/build/IconComponents";

import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import Table from "../../core/pagination/datatable";
import { DatePicker, Pagination, Select, Tabs } from "antd";
import {
  deleteReasonType,
  getReasonTypeguid,
  getReasonTypeList,
} from "../../services/MasterApiServices";
import ReasonTypeModal from "../../components/master/modal/ReasonTypeModal";
import { OverlayTrigger, TabPane, Tooltip } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import ImageWithBasePath from "../../core/img/imagewithbasebath";
import { setheadertoggle } from "../../redux/toggle";
import { renderTooltip } from "../../redux/helper";
import {
  printReport,
  exportExcel,
  exportPDF,
} from "../../core/utils/reportUtils";
import Receipt from "../../components/accounting/RecieptModal";

import PaymentModal from "../../components/accounting/PaymentModal";
import {
  deletePayment,
  getAccountledger,
  getcashorbank,
  getPaymentByGuid,
} from "../../services/AccountApiServices";
import dayjs from "dayjs";
import { Controller } from "react-hook-form";
import { PaymentEntry } from "../../core/json/PaymentEntry";

function PaymentMaster() {
  const { branch } = useSelector((state) => state.branchlist);
  const { userId } = useSelector((state) => state.userauth);
  const dispatch = useDispatch();
  const [selectedLedger, setSelectedLedger] = useState(null);
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState();
  const [ledgerData, setLedgerData] = useState();
  const [searchText, setSearchText] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const [modalMode, setModalMode] = useState("add");
  const [PaymentData, setPaymentData] = useState([]);
  const [updateData, setUpdateData] = useState([]);
  const [activeTab, setActiveTab] = useState("1");
  const [itemwiseData, setitemwiseData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [filtereditemData, setFiltereditemData] = useState([]);
  const [loading, setLoading] = useState(false);
  const { togglehead } = useSelector((state) => state.headerToggle);

  const [permit, setpermit] = useState();
  const handletogglehead = () => {
    dispatch(setheadertoggle(!togglehead));
  };
  useEffect(() => {
    handlePaymentList();
    handleLedgerList();
  }, []);

  const itemwiseDataFn = (data) => {
    return data.paymentDetails?.map((item, index) => {
      return {
        date: data.date,
        no: data.no,
        ledgerName: data.ledgerName,
        ledger: item.ledgerName || "Unknown ledger",
        amount: item.amount || 0.0,
        ledgerID: item.ledgerID,
      };
    });
  };

  const handlePaymentList = async () => {
    setLoading(true);
    try {
      const response = await getPaymentByGuid(branch);
      if (Array.isArray(response)) {
        setPaymentData(response);
        setFilteredData(response);
        const allItemwiseData = response.flatMap((data) =>
          itemwiseDataFn(data)
        );
        setitemwiseData(allItemwiseData);
        setFiltereditemData(allItemwiseData);
        setLoading(false);
      } else {
        setPaymentData([]);
        setLoading(false); // If response is not an array, reset to an empty array
      }
    } catch (error) {
      console.log(error?.response?.data?.Message || "something went wrong");
      setPaymentData([]);
      setLoading(false);
    }
  };
  const handleTabChange = (key) => {
    console.log("Active Tab:", key);
    setActiveTab(key);
  };
  const handleSearch = (e) => {
    setSearchText(e.target.value);
  };
  const handleLedgerList = async () => {
    try {
      const response = await getcashorbank();
      if (Array.isArray(response?.data)) {
        setLedgerData(
          response?.data?.map((e) => ({
            value: e.guid,
            label: e.name,
          }))
        );
      } else {
        setLedgerData([]);
      }
    } catch (error) {
      console.log(error?.response?.data?.Message || "Something went wrong");
      setLedgerData([]);
    }
  };
  useEffect(() => {
    handleFilter();
  }, [searchText]);

  const handleEditClick = (guid) => {
    try {
      const paymentByGuid = PaymentData.find((data) => data.guid === guid);

      setUpdateData(paymentByGuid);
      setModalMode("edit");
      setModalOpen(true);
    } catch (error) {
      console.error(
        "Error fetching payment by GUID:",
        error?.response?.data?.Message
      );
    }
  };
  const handleDeleteClick = (obj) => {
    const objpayment = new PaymentEntry();
    objpayment.Branch = branch;
    objpayment.guid = obj.guid;
    objpayment.id = obj.id;
    objpayment.updatedUser = userId?.id;
    objpayment.createdUser = userId?.id;
    MySwal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#00ff00",
      cancelButtonColor: "#ff0000",
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "Cancel",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const response = await deletePayment(objpayment);
          if (response.data == true) {
            MySwal.fire({
              title: "Deleted!",
              text: "The payment has been deleted.",
              icon: "success",
              confirmButtonColor: "#00ff00",
              confirmButtonText: "OK",
            }).then(() => {
              handlePaymentList();
              setSearchText("");
            });
          } else {
            MySwal.fire({
              title: "Error!",
              text: "Something went wrong.",
              icon: "error",
              confirmButtonColor: "#ff0000",
              confirmButtonText: "OK",
            });
          }
        } catch (error) {
          console.error("Error deleting sales quotation:", error);
          MySwal.fire({
            title: "Error!",
            text: error?.response?.data?.Message || "something went wrong",
            icon: "error",
            confirmButtonColor: "#ff0000",
            confirmButtonText: "OK",
          });
        }
      }
    });
  };

  const columns = [
    {
      title: "Entry#",
      dataIndex: "no",
      sorter: (a, b) => a.no - b.no,
    },
    {
      title: "Date",
      dataIndex: "date",
      sorter: (a, b) => (dayjs(a.date).isAfter(dayjs(b.date)) ? 1 : -1),
      defaultSortOrder: "descend",
      render: (date) => dayjs(date).format("DD-MM-YY"),
    },
    {
      title: "Cash/Bank",
      dataIndex: "ledgerName",
      sorter: (a, b) => a.ledgerName?.length - b.ledgerName?.length,
    },
    {
      title: "Remarks",
      dataIndex: "narration",
      sorter: (a, b) => a.narration - b.narration,
    },
    {
      title: "Amount",
      dataIndex: "totalAmt",
      sorter: (a, b) => a.totalAmt - b.totalAmt,
    },
    // ...(permit?.edit || permit?.deletion
    //   ? [
    {
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
      render: (_, obj) => (
        <div className="action-table-data">
          <div className="edit-delete-action">
            {/* {permit?.edit && ( */}
            <Link
              className="me-2 p-2"
              to="#"
              onClick={() => handleEditClick(obj.guid)}
            >
              <i data-feather="edit" className="feather-edit"></i>
            </Link>
            {/* )}
            {permit?.deletion && ( */}
            <Link
              className="confirm-text p-2"
              to="#"
              onClick={() => handleDeleteClick(obj)}
            >
              <i data-feather="trash-2" className="feather-trash-2"></i>
            </Link>
            {/* )} */}
          </div>
        </div>
      ),
    },
  ];
  //     : []),
  // ];

  const columnsWithoutActions = [
    {
      title: "Bill No",
      dataIndex: "no",
      sorter: (a, b) => a.no - b.no,
    },
    {
      title: "Date",
      dataIndex: "date",
      sorter: (a, b) => (dayjs(a.date).isAfter(dayjs(b.date)) ? 1 : -1),
      defaultSortOrder: "descend",
      render: (date) => dayjs(date).format("DD-MM-YY"),
    },
    {
      title: "Cash/Bank",
      dataIndex: "ledgerName",
      sorter: (a, b) => a.ledgerName?.length - b.ledgerName?.length,
    },
    {
      title: "Remarks",
      dataIndex: "narration",
      sorter: (a, b) => a.narration - b.narration,
    },
    {
      title: "Amount",
      dataIndex: "totalAmt",
      sorter: (a, b) => a.totalAmt - b.totalAmt,
      align: "right",
    },
  ];
  const detailedColumns = [
    {
      title: "Bill No",
      dataIndex: "no",
      sorter: (a, b) => a.no - b.no,
    },
    {
      title: "Date",
      dataIndex: "date",
      sorter: (a, b) => (dayjs(a.date).isAfter(dayjs(b.date)) ? 1 : -1),
      defaultSortOrder: "descend",
      render: (date) => dayjs(date).format("DD-MM-YY"),
    },
    {
      title: "Cash/Bank",
      dataIndex: "ledgerName",
      sorter: (a, b) => a.ledgerName?.length - b.ledgerName?.length,
    },
    {
      title: "Ledger",
      dataIndex: "ledger",
      sorter: (a, b) => a.ledger?.length - b.ledger?.length,
    },
    {
      title: "Amount",
      dataIndex: "amount",
      sorter: (a, b) => a.amount - b.amount,
      align: "right",
    },
  ];
  const MySwal = withReactContent(Swal);
  function handleReset() {
    setFromDate(null);
    setToDate(null);
    setSelectedLedger(null);
    setSearchText("");
    setFilteredData(PaymentData);
    setFiltereditemData(itemwiseData);
  }

  const handleLedgerChange = (selectedOption) => {
    setSelectedLedger(selectedOption);
  };
  const handleFromDateChange = (date) => {
    setFromDate(date);
    setToDate(null);
    console.log(date, "from date");
  };

  const handleToDateChange = (date) => {
    setToDate(date);
    console.log(date, "to date");
  };
  function handleFilter() {
    console.log("handle filter working-------------------------");
    console.log("Original Data:", PaymentData);
    console.log("Search Text:", searchText);
    console.log("From Date:", fromDate, "To Date:", toDate);
    console.log("selectedLedger:", selectedLedger);
    // console.log("Selected Supplier:", selectedSupplier);

    const filteredData = PaymentData?.filter((entry) => {
      const ledgerMatch = !selectedLedger || entry.ledgerID === selectedLedger;

      const fromDateMatch =
        !fromDate || new Date(entry.date) >= new Date(fromDate);
      const toDateMatch = !toDate || new Date(entry.date) <= new Date(toDate);
      const searchTextL = searchText?.toLowerCase() || "";

      const searchTextMatch =
        dayjs(entry?.date).format("YYYY-MM-DD")?.includes(searchTextL) ||
        entry?.no?.toString().includes(searchTextL) ||
        entry?.ledgerName?.toLowerCase().includes(searchTextL) ||
        entry?.narration?.toString().includes(searchTextL) ||
        entry?.totalAmt?.toString().includes(searchTextL);
      if (searchTextMatch) {
        console.log(searchTextMatch, "searchTextMatch");
        console.log(entry, "entry");
      }
      return fromDateMatch && toDateMatch && searchTextMatch && ledgerMatch;
    });

    console.log("filtered Data:", filteredData);
    setFilteredData(filteredData);

    handleFilteritem();
  }
  function handleFilteritem() {
    console.log(itemwiseData, "itemwisedata");
    const filteredData = itemwiseData?.filter((entry) => {
      const ledgerMatch = !selectedLedger || entry.ledgerID === selectedLedger;
      const fromDateMatch =
        !fromDate || new Date(entry.date) >= new Date(fromDate);
      const toDateMatch = !toDate || new Date(entry.date) <= new Date(toDate);
      const searchTextL = searchText?.toLowerCase() || "";

      const searchTextMatch =
        dayjs(entry?.date).format("YYYY-MM-DD")?.includes(searchTextL) ||
        entry?.no?.toString().includes(searchTextL) ||
        entry?.ledger?.toLowerCase().includes(searchTextL) ||
        entry?.ledgerName?.toLowerCase().includes(searchTextL) ||
        entry?.amount?.toString().includes(searchTextL);
      if (searchTextMatch) {
        console.log(searchTextMatch, "searchTextMatch");
        console.log(entry, "entry");
      }
      return fromDateMatch && toDateMatch && searchTextMatch && ledgerMatch;
    });

    setFiltereditemData(filteredData);
  }
  const getActiveTabData = () => {
    let fieldMapping = {};

    // Function to create a column mapping (column title to dataIndex)
    const createfieldMapping = (columns) => {
      const mappings = {};
      columns.forEach((col) => {
        mappings[col.title] = col.dataIndex; // Map column title to its dataIndex
      });
      return mappings;
    };
    switch (activeTab) {
      case "1":
        fieldMapping = createfieldMapping(columns);
        console.log("fieldMapping of first table:", fieldMapping);
        return { data: filteredData, columns: columns, fieldMapping };
      case "2":
        fieldMapping = createfieldMapping(columnsWithoutActions);
        console.log("fieldMapping of second table:", fieldMapping);
        return {
          data: filteredData,
          columns: columnsWithoutActions,
          fieldMapping,
        };
      case "3":
        fieldMapping = createfieldMapping(detailedColumns);
        console.log("fieldMapping of third table:", fieldMapping);
        return {
          data: filtereditemData,
          columns: detailedColumns,
          fieldMapping,
        };
      default:
        return { data: [], columns: [] };
    }
  };
  return (
    <div>
      <div>
        <div className="page-wrapper">
          <div className="content">
            <div className="page-header transfer">
              <div className="add-item d-flex">
                <div className="page-title">
                  <h4>Payment</h4>
                  <h6>Manage Payment here</h6>
                </div>
              </div>
              <ul className="table-top-head">
                <li>
                  <OverlayTrigger
                    placement="top"
                    overlay={renderTooltip("PDF")}
                  >
                    <Link
                      onClick={() =>
                        exportPDF(getActiveTabData, "Payment Master")
                      }
                    >
                      <ImageWithBasePath
                        src="assets/img/icons/pdf.svg"
                        alt="img"
                      />
                    </Link>
                  </OverlayTrigger>
                </li>
                <li>
                  <OverlayTrigger
                    placement="top"
                    overlay={renderTooltip("Excel")}
                  >
                    <Link
                      onClick={() =>
                        exportExcel(getActiveTabData, "Payment Master")
                      }
                    >
                      <ImageWithBasePath
                        src="assets/img/icons/excel.svg"
                        alt="img"
                      />
                    </Link>
                  </OverlayTrigger>
                </li>
                <li>
                  <OverlayTrigger
                    placement="top"
                    overlay={renderTooltip("Print")}
                  >
                    <Link
                      onClick={() =>
                        printReport(getActiveTabData, "Payment Master")
                      }
                    >
                      <i data-feather="printer" className="feather-printer" />
                    </Link>
                  </OverlayTrigger>
                </li>
                <li>
                  <OverlayTrigger
                    placement="top"
                    overlay={renderTooltip("Refresh")}
                  >
                    <Link
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      onClick={() => handlePaymentList()}
                    >
                      <RotateCcw />
                    </Link>
                  </OverlayTrigger>
                </li>
                <li>
                  <OverlayTrigger
                    placement="top"
                    overlay={renderTooltip("Collapse")}
                  >
                    <Link
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      id="collapse-header"
                      className={togglehead ? "active" : ""}
                      onClick={handletogglehead}
                    >
                      <ChevronUp />
                    </Link>
                  </OverlayTrigger>
                </li>
              </ul>
              <ul className="table-top-head"></ul>
              <div className="d-flex purchase-pg-btn">
                <div className="page-btn">
                  <Link
                    to="#"
                    className="btn btn-added"
                    onClick={() => {
                      setModalMode("add"); // Set the modal mode to 'add'
                      setModalOpen(true); // Open the modal
                    }}
                  >
                    <PlusCircle className="me-2" />
                    New
                  </Link>
                </div>
              </div>
            </div>

            <div className="card table-list-card">
              <div className="card-body">
              <div className="d-flex flex-wrap align-items-center gap-3 m-2">
  {/* Search Input */}
  <div className="flex-grow-1">
    <input
      type="text"
      placeholder="Search"
      className="form-control form-control-sm"
      value={searchText}
      onChange={handleSearch}
      style={{ height: "38px" }}
    />
  </div>

  {/* From Date */}
  <div className="flex-grow-1">
  <div className="input-blocks ms-4">
                      <label>From Date</label>
                      <div className="input-groupicon calender-input">
                        <Calendar className="info-img" />
                        <DatePicker
                            className="datetimepicker"
                            id="from-date"
                            placeholderText="From Date"
                            value={fromDate}
                            onChange={handleFromDateChange}
                            startDate={fromDate}
                            selectsStart
                            dateFormat="dd/MM/yyyy"
                            style={{ height: "38px", width: "100%" }} // Inline styles for height and width
                          />
                      </div>
                    </div>
    
  </div>

  {/* To Date */}
  <div className="flex-grow-1">
  <div className="input-blocks">
                      <label>To Date</label>
                      <div className="input-groupicon calender-input">
                      <DatePicker
                            id="to-date"
                            placeholderText="To Date"
                            value={toDate}
                            onChange={handleToDateChange}
                            minDate={fromDate}
                            startDate={fromDate}
                            selectsEnd
                            disabled={!fromDate}
                            dateFormat="dd/MM/yyyy"
                            style={{ height: "38px", width: "100%" }} // Inline styles for height and width
                          />
                      </div>
                    </div>
   
  </div>

  {/* Product Dropdown */}
  <div className="flex-grow-1">
  <div className="input-blocks">
                        <label>
                          {" "}
                          {activeTab === "3" ? "Ledger" : "Cash/Bank"}
                        </label>

                        <div className="input-groupicon ">
                          <Select
                            className="img-select"
                            options={ledgerData}
                            onChange={handleLedgerChange}
                            allowClear
                            value={selectedLedger}
                            classNamePrefix="react-select"
                            placeholder="Select "
                            style={{ height: "38px", width: "100%" }}
                          />
                        </div>
                      </div>
  </div>

  {/* Supplier Dropdown */}
  

  {/* Buttons */}
  <div className="d-flex gap-2">
    <button className="btn btn-primary" onClick={handleFilter}>
      Search
    </button>
    <button className="btn btn-secondary" onClick={handleReset}>
      Reset
    </button>
  </div>
</div>
                {/* <div className="table-top">
                  <div className="search-set">
                    <div className="search-input">
                      <input
                        type="text"
                        placeholder="Search"
                        className="form-control form-control-sm me-2 formsearch "
                        aria-controls="DataTables_Table_0"
                        value={searchText}
                        onChange={handleSearch}
                      />
                      <Link to className="btn btn-searchset btn-sm rounded-2">
                        <i data-feather="search" className="feather-search" />
                      </Link>
                    </div>
                  </div>

                  <div className="d-flex justify-content-end">
                    {/* {isFilterVisible && ( */}
                    {/* <div className="input-blocks me-2">
                      <button
                        className="btn btn-filters btn-sm rounded-2 h-100"
                        onClick={handleFilter}
                      >
                        <i data-feather="search" className="feather-search" />{" "}
                        Search
                      </button>
                    </div>
                    {/* )} */}

                    {/* <div className="input-blocks">
                      <button
                        className="btn btn-reset  rounded-2 "
                        onClick={handleReset}
                      >
                        <i
                          data-feather="refresh-cw"
                          className="feather-refresh-cw"
                        />{" "}
                        Reset
                      </button>
                    </div>
                  </div>
                </div>   */}

                {/* <div className="card-body">
                  <div className="row">
                    {/* From Date Filter */}
                    {/* <div className="col-lg-2 col-sm-6 col-12">
                      <div className="input-blocks ms-4">
                        <label>From Date</label>
                        <div className="input-groupicon ">
                          <Calendar className="info-img" />
                          <DatePicker
                            className="datetimepicker"
                            id="from-date"
                            placeholderText="From Date"
                            value={fromDate}
                            onChange={handleFromDateChange}
                            startDate={fromDate}
                            selectsStart
                            dateFormat="dd/MM/yyyy"
                            style={{ height: "38px", width: "100%" }} // Inline styles for height and width
                          />
                        </div>
                      </div>
                    </div> */}

                    {/* To Date Filter */}
                    {/* <div className="col-lg-2 col-sm-6 col-12">
                      <div className="input-blocks">
                        <label>To Date</label>
                        <div className="input-groupicon ">
                          <Calendar className="info-img" />
                          <DatePicker
                            id="to-date"
                            placeholderText="To Date"
                            value={toDate}
                            onChange={handleToDateChange}
                            minDate={fromDate}
                            startDate={fromDate}
                            selectsEnd
                            disabled={!fromDate}
                            dateFormat="dd/MM/yyyy"
                            style={{ height: "38px", width: "100%" }} // Inline styles for height and width
                          />
                        </div>
                      </div>
                    </div> 

                    <div className="col-lg-2 col-sm-6 col-12">
                      <div className="input-blocks">
                        <label>
                          {" "}
                          {activeTab === "3" ? "Ledger" : "Cash/Bank"}
                        </label>

                        <div className="input-groupicon ">
                          <Select
                            className="img-select"
                            options={ledgerData}
                            onChange={handleLedgerChange}
                            allowClear
                            value={selectedLedger}
                            classNamePrefix="react-select"
                            placeholder="Select "
                            style={{ height: "38px", width: "100%" }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* /Filter */}
                <div className="table-responsive product-list">
                  <Tabs defaultActiveKey="1" onChange={handleTabChange}>
                    <TabPane tab="Payment" key="1">
                      <Table columns={columns} dataSource={filteredData} />
                    </TabPane>
                    <TabPane tab="Summary Reports" key="2">
                      <Table
                        columns={columnsWithoutActions}
                        dataSource={filteredData}
                      />
                    </TabPane>
                    <TabPane tab="Detailed Reports" key="3">
                      <Table
                        columns={detailedColumns}
                        dataSource={filtereditemData}
                      />
                    </TabPane>
                  </Tabs>
                </div>
              </div>
            </div>
            {/* /product list */}
          </div>
        </div>
        <Pagination />
        {modalOpen && (
          <PaymentModal
            mode={modalMode}
            data={modalMode === "edit" ? updateData : {}}
            handleClose={() => setModalOpen(false)}
            handleRefresh={() => {
              handlePaymentList();
              setSearchText("");
            }}
          />
        )}
      </div>
    </div>
  );
}

export default PaymentMaster;
