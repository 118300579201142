import React, { useState, useEffect } from "react";
import { useForm, useFieldArray } from "react-hook-form";
import { DatePicker } from "antd";
import Select from "react-select";
import { Calendar } from "feather-icons-react/build/IconComponents";
import { useSelector } from "react-redux";
import { InvSales } from "../../core/json/invSales";

import {
  getbillwisepayment,
  getbillwisereceipt,
  getcusBill,
  getsupBill,
  savebillpayment,
  savebillreceipt,
} from "../../services/AccountApiServices";
import { Controller } from "react-hook-form";
import dayjs from "dayjs";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import { BillPaymentEntry } from "../../core/json/BillPaymentEntry";
import { FinancialYear } from "../../core/json/FinancialYear";
import { BillPaymentDetail } from "../../core/json/BillPaymentDetail";
import { Currency } from "../../core/json/Currency";
import { fetchBranchSettings } from "../../redux/settingbyBranch";
import { SupplierClass } from "../../core/json/SupplierClass";
import { BillReceiptEntry } from "../../core/json/BillReceiptEntry";
import { BillReceiptDetail } from "../../core/json/BillReceiptDetails";
import { CustomerClass } from "../../core/json/Customer";
const dummydata = [
  {
    billtype: "p",
    billno: "1",
    date: new Date(),
    billamount: 2500,
    paid: 100,
    discount: 2,
    inNo: "i1",
    inDate: new Date(),
  },
  {
    billtype: "p",
    billno: "2",
    date: new Date(),
    billamount: 3000,
    paid: 250,
    discount: 20,
    inNo: "i2",
    inDate: new Date(),
  },
  {
    billtype: "p",
    billno: "3",
    date: new Date(),
    billamount: 400,
    paid: 10,
    discount: 0,
    inNo: "i3",
    inDate: new Date(),
  },
];
const Billreceiptmodal = ({
  mode,
  data,
  handleClose,
  handleRefresh,
  bankorcash,
}) => {
  const {
    register,
    setValue,
    getValues,
    handleSubmit,
    watch,
    control,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      deliveryDate: dayjs().format("YYYY-MM-DD"),
      invdate: dayjs().format("YYYY-MM-DD"),
      isCheque: false,
      amount: 0,
    },
    mode: "onBlur",
  });
  const isCheque = watch("isCheque");
  const { fields, append, remove } = useFieldArray({
    control,
    name: "items",
  });

  const dispatch = useDispatch();
  const [modalOpen, setModalOpen] = useState(false);
  const [isRowAdded, setIsRowAdded] = useState(false);
  const [decimalpoints, setDecimalPoints] = useState(2);
  const [supBill, setsupBill] = useState([]);
  const { formatedcustomerList } = useSelector((state) => state.usersetting);
  const { companyInfolist } = useSelector((state) => state.companyInfo);
  const { userId } = useSelector((state) => state.userauth);
  const [customer, setCustomer] = useState(null);
  const { branch } = useSelector((state) => state.branchlist);
  const { branchsettings } = useSelector((state) => state.settings);
  const [amount, setamount] = useState(0);
  useEffect(() => {
    dispatch(fetchBranchSettings(branch.guid));
  }, [branch]);
  async function handleFill() {
    if (!customer || amount<=0) {
      return;
    }
    await handlebill(customer);
    const amount = parseFloat(watch("amount", 0)) || 0;
    let remainingAmount = amount;
    if (amount <= totalbalance) {
      fields.forEach((field, index) => {
        const balance = parseFloat(watch(`items[${index}].balance`, 0)) || 0;

        if (remainingAmount > 0) {
          const payingAmount = Math.min(remainingAmount, balance);
          setValue(
            `items[${index}].paying`,
            payingAmount.toFixed(decimalpoints) || 0
          );
          setValue(
            `items[${index}].balance`,
            (balance - payingAmount).toFixed(decimalpoints) || 0
          );
          remainingAmount -= payingAmount;
        }
        if (remainingAmount <= 0) {
          setValue("amount", 0);
          setamount(0)
        }
      });
    }
  }
  // Get branch from redux
  // Fetch sales quotations data
  const handleEntryNo = async () => {
    try {
      const response = await getbillwisereceipt(branch);
      const receiptData = response.data;
      // Find the maximum number from the existing entries
      const maxEntryNumber =
        receiptData?.length > 0
          ? receiptData
              ?.map((data) => parseInt(data.no))
              ?.reduce((max, num) => Math.max(max, num), 0) // Find the maximum number
          : 0;
      let counter = maxEntryNumber + 1;
      setValue(`no`, counter);
      counter++;
    } catch (error) {
      console.error("Failed to fetch purchase invoices", error);
    }
  };

  function resetmodal() {
    if (mode === "edit" && data) {
      setValue("no", data.no);
      setValue("paymentdate", data.date);
      setValue("customer", data.customer.guid);
      setValue("ledger", data.ledgerID);
      setValue("isCheque", data.isCheque);
      // setValue("amount", data.amount);
      setamount(data.amount)
      setValue("remark", data.narration);
      const groupedData = data?.billReceiptDetails.reduce((acc, e) => {
        const key = e.entryNumber;
        if (!acc[key]) {
          acc[key] = { ...e, amount: e.amount, discount: e.discount }; // Initialize the group
        } else {
          acc[key].amount += e.amount; // Sum amounts for the same entryNumber
          acc[key].discount += e.discount; // Sum discounts for the same entryNumber
        }
        return acc;
      }, {});
      const detail = Object.values(groupedData).map((e) => {
        const bal = (e.netTotal - (e.amount + e.discount)).toFixed(2); // Rounds balance to 2 decimal places

        // Return the transformed object
        return {
          masterID: e.masterID,
          salesMasterID: e.salesMasterID,
          salesReturnMasterID: e.salesReturnMasterID,
          billtype: e.transType,
          billno: e.entryNumber,
          date: dayjs(e.entryDate).format("DD-MM-YYYY"),
          billamount: e.netTotal,
          paid: e.amount,
          paying: e.amount,
          discount: e.discount,
          invNo: e.invoiceNO,
          invdate: dayjs(e.entryDate).format("DD-MM-YYYY"), // Correct usage of dayjs
          balance: bal > 0 ? bal : 0,
        };
      });

      setValue("items", detail);
    } else {
      reset();
      handleEntryNo();
    }
  }

  useEffect(() => {
    resetmodal();
  }, [data, append, remove]);

  useEffect(() => {
    const fetchData = async () => {
      if (mode === "add") {
        handleEntryNo();
        if (!isRowAdded && fields?.length === 0) {
          setIsRowAdded(true);
        }
      }
    };

    fetchData();
  }, []);
  const handlesaveorupdate = async (formData) => {
    console.log(formData);
    console.log(branchsettings, "PrimaryCurrency");

    const objbillreceipt = new BillReceiptEntry();
    const objfinance = new FinancialYear();
    const objcurrency = new Currency();
    const objcustomer = new CustomerClass();
    objfinance.guid = branchsettings?.defaultFinancialYearGuID;
    objcurrency.guid = branchsettings?.primaryCurrencyGUID;
    objbillreceipt.guid = mode == "edit" ? data.guid : "";
    objbillreceipt.id = mode == "edit" ? data.id : 0;
    objbillreceipt.Currency = objcurrency;
    objbillreceipt.date = dayjs(formData.invdate).format("YYYY-MM-DD");
    objbillreceipt.No = formData.no;
    objcustomer.Guid = formData.customer;
    objbillreceipt.Customer = objcustomer;
    objbillreceipt.Narration = formData.remark;
    objbillreceipt.Amount = parseFloat(formData.amount);
    objbillreceipt.LedgerID = formData.ledger;
    objbillreceipt.Branch = branch;
    objbillreceipt.FinancialYear = objfinance;
    objbillreceipt.Company = companyInfolist[0];
    objbillreceipt.createdUser = userId.id;
    objbillreceipt.updatedUser = userId.id;

    for (const { select, masterID, salesMasterID, paying, discount } of formData.items) {
      if (select || paying> 0) {
        const objdetail = new BillReceiptDetail();
        objdetail.MasterID = masterID;
        objdetail.SalesMasterID = salesMasterID;
        objdetail.SalesReturnMasterID = salesMasterID;
        objdetail.Amount = parseFloat(paying) || 0;
        objdetail.Discount = parseFloat(discount) || 0;
    
        objbillreceipt.billReceiptDetails.push(objdetail);
      }
    }
    try {
      console.log(objbillreceipt, "PAYLOAD");
      const response = await savebillreceipt(objbillreceipt);
      if (response.data == true) {
        // fields.forEach((_, index) => remove(index))
        toast.success("success");
        handleClose();
        handleRefresh();
      } else {
        toast.success(response.data);
      }
    } catch (error) {
      toast.error(error?.response?.data?.Message);
      console.error("Error handling category:", error?.response?.data?.Message);
    }
  };

  const handlebill = async (val) => {
    setValue("amount", '')
    setamount(0)
    try {
      const response = await getcusBill(val, branch);
      console.log(response.data, "Response Data");

      if (Array.isArray(response.data)) {
        const groupedData = response.data.reduce((acc, e) => {
          const key = e.entryNumber;
          if (!acc[key]) {
            acc[key] = { ...e, amount: e.amount, discount: e.discount }; // Initialize the group
          } else {
            acc[key].amount += e.amount; // Sum amounts for the same entryNumber
            acc[key].discount += e.discount; // Sum discounts for the same entryNumber
          }
          return acc;
        }, {});
        console.log(groupedData,"grp");
        
        const data = Object.values(groupedData).map((e) => {
          const bal = (e.netTotal - (e.amount + e.discount)).toFixed(2); // Rounds balance to 2 decimal places
        console.log(e.netTotal, e.amount);
        
          return {
            masterID: e.masterID,
            salesMasterID: e.salesMasterID,
            salesReturnMasterID: e.salesReturnMasterID,
            billtype: e.transType,
            billno: e.entryNumber,
            date: dayjs(e.entryDate).format("DD-MM-YYYY"),
            billamount: e.netTotal,
            paid: e.amount,
            discount: e.discount,
            invNo: e.invoiceNO,
            invdate: dayjs(e.entryDate).format("DD-MM-YYYY"), // Correct usage of dayjs
            balance: bal ,
          };
        });
        console.log(data, "Formatted Data");

        setValue("items", data);
        setsupBill(data);
      } else {
        setsupBill([]);
      }
    } catch (error) {
      console.error("Error fetching bill data:", error);
      setsupBill([]);
    }
  };
  const totalamount = fields?.reduce(
    (sum, _, index) =>
      sum + parseFloat(watch(`items[${index}].billamount`) || 0),
    0
  );
  const totalpaid = fields?.reduce(
    (sum, _, index) => sum + parseFloat(watch(`items[${index}].paid`) || 0),
    0
  );
  const totalpaying = fields?.reduce(
    (sum, _, index) => sum + parseFloat(watch(`items[${index}].paying`) || 0),
    0
  );
  const totaldiscount = fields?.reduce(
    (sum, _, index) => sum + parseFloat(watch(`items[${index}].discount`) || 0),
    0
  );
  const totalbalance = fields?.reduce(
    (sum, _, index) => sum + parseFloat(watch(`items[${index}].balance`) || 0),
    0
  );
  function handleAddPaying(index) {
    const billamount = getValues(`items[${index}].billamount`) || 0;
    const paid = getValues(`items[${index}].paid`) || 0;
    const paying = getValues(`items[${index}].paying`) || 0;
    const discount = getValues(`items[${index}].discount`) || 0;
    setValue('amount', totalpaying)
    setamount(totalpaying)
    console.log("paying:", paying);
    console.log("discount:", discount);

    const balance = billamount - paid;
    const finalBalance = balance - paying - discount;

    console.log("balance:", balance);
    console.log("finalBalance:", finalBalance);

    if (finalBalance >= 0) {
      setValue(`items[${index}].balance`, finalBalance?.toFixed(2));
    }
  }
  return (
    <div>
      <div
        className="modal fade show"
        style={{ display: "block" }}
        tabIndex="-1"
      >
        <div className="modal-dialog add-centered">
          <div className="modal-content">
            <div className="page-wrapper p-0 m-0">
              <div className="content p-0">
                <div className="modal-header border-0 custom-modal-header">
                  <div className="page-title">
                    <h4>
                      {" "}
                      {mode === "edit"
                        ? "Edit Bill Wise Receipt"
                        : "Add Bill Wise Receipt"}
                    </h4>
                  </div>
                  <button type="button" className="close" onClick={handleClose}>
                    <span aria-hidden="true">×</span>
                  </button>
                </div>
                <div className="card">
                  <div className="card-body">
                    <form onSubmit={handleSubmit(handlesaveorupdate)}>
                      <div className="row">
                        {/* row1 */}
                        <div className="col-lg-4 col-sm-6 col-12">
                          <div className="input-blocks">
                            <label className="form-label">Entry No.</label>
                            <input
                              type="text"
                              className="form-control"
                              {...register("no")}
                              required
                              disabled
                            />
                          </div>
                        </div>
                        <div className="col-lg-4 col-sm-6 col-12">
                          <div className="input-blocks">
                            <label>Date</label>
                            <div className="input-groupicon calender-input">
                              <Calendar className="info-img" />
                              <Controller
                                control={control}
                                name="paymentdate"
                                render={({ field: { onChange, value } }) => (
                                  <DatePicker
                                    className="datetimepicker"
                                    value={value ? dayjs(value) : dayjs()}
                                    format="DD-MM-YYYY"
                                    onChange={(date) =>
                                      onChange(
                                        date ? date.format("YYYY-MM-DD") : null
                                      )
                                    } // Update on change
                                  />
                                )}
                              />
                            </div>
                          </div>
                        </div>

                        <div className="col-lg-4 col-sm-6 col-12">
                          <div className="input-blocks add-product">
                            <label>
                              Customer<span className="text-danger">*</span>
                            </label>

                            <Controller
                              control={control}
                              name={`customer`}
                              render={({ field: { onChange, value } }) => (
                                <Select
                                  
                                  classNamePrefix="react-select"
                                  options={formatedcustomerList}
                                  value={
                                    formatedcustomerList?.find(
                                      (customer) => customer?.value === value
                                    ) || null
                                  }
                                  onChange={(selectedOption) => {
                                    onChange(selectedOption.value || null);
                                    setCustomer(selectedOption.value);
                                    handlebill(selectedOption.value);
                                  }}
                                  styles={{
                                    menu: (provided) => ({
                                      ...provided,
                                      zIndex: 9999,
                                    }),
                                  }}
                                />
                              )}
                            />
                          </div>
                        </div>
                        <div className="col-lg-4 col-sm-6 col-12">
                          <div className="input-blocks">
                            <label className="form-label">
                              Ledger<span className="text-danger">*</span>
                            </label>
                            <div className="row">
                              <Controller
                                control={control}
                                name="ledger"
                                rules={{
                                  required: "Selecting a ledger is required", // Validation rule for required
                                }}
                                render={({
                                  field: { onChange, value },
                                  fieldState: { error }, // Access error object to show validation message
                                }) => (
                                  <>
                                    <Select
                                      isClearable
                                      classNamePrefix="react-select"
                                      options={bankorcash}
                                      value={
                                        bankorcash?.find(
                                          (bank) => bank?.value === value
                                        ) || null
                                      }
                                      onChange={(selectedOption) =>
                                        onChange(selectedOption?.value || null)
                                      }
                                      styles={{
                                        menu: (provided) => ({
                                          ...provided,
                                          zIndex: 9999,
                                        }),
                                      }}
                                    />
                                    {error && (
                                      <p className="text-danger">
                                        {error.message}
                                      </p>
                                    )}
                                  </>
                                )}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-4 col-sm-6 col-12">
                          <div className="input-blocks">
                            <div className="form-check form-switch mt-4">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                {...register("isCheque")}
                              />
                              <label className="form-check-label">
                                Cheque ?
                              </label>
                            </div>
                          </div>
                        </div>

                        {isCheque && (
                          <>
                            {["chequeNo", "accNo", "holdername"].map(
                              (field, index) => (
                                <div
                                  key={index}
                                  className="col-lg-4 col-sm-6 col-12"
                                >
                                  <div className="input-blocks">
                                    <label className="form-label">{`${
                                      field.charAt(0).toUpperCase() +
                                      field.slice(1).replace(/([A-Z])/g, " $1")
                                    }`}</label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      {...register(field)}
                                      required
                                    />
                                  </div>
                                </div>
                              )
                            )}

                            <div className="col-lg-4 col-sm-6 col-12">
                              <div className="input-blocks">
                                <label>Cheque Date</label>
                                <div className="input-groupicon calender-input">
                                  <Calendar className="info-img" />
                                  <Controller
                                    control={control}
                                    name="chequedate"
                                    render={({
                                      field: { onChange, value },
                                    }) => (
                                      <DatePicker
                                        className="datetimepicker"
                                        value={value ? dayjs(value) : dayjs()}
                                        format="DD-MM-YYYY"
                                        onChange={(date) =>
                                          onChange(
                                            date
                                              ? date.format("YYYY-MM-DD")
                                              : null
                                          )
                                        }
                                      />
                                    )}
                                  />
                                </div>
                              </div>
                            </div>
                          </>
                        )}
                      </div>
                      <div className="row">
                        <div className="col-lg-2 col-sm-6 col-6">
                          <div className="input-blocks">
                            <label className="form-label">Amount</label>
                            <input
                              type="number"
                              min={0}
                              step="any"
                              value={amount}
                              onChange={(e)=>setamount(e.target.value)}
                              className="form-control"
                              // {...register("amount", { 
                              //   required: "Amount is required",
                              //   validate: {
                              //     withinBalance: (value) =>
                              //       (!isNaN(value) && value <= totalbalance) ||
                              //       "Amount can't be greater than balance",
                              //   },
                              // })}
                              
                            />
                            {amount > totalbalance && (
                              <span className="error text-danger text-sm">
                                Amount cant be greater than balance
                              </span>
                            )}
                          </div>
                        </div>
                        <div className="col-lg-4 col-sm-6 col-12">
                          <div className="input-blocks mt-1">
                            {/* <label className="form-label">Amount</label> */}
                            <button
                              className="btn btn-success mt-4 px-4"
                              onClick={handleFill}
                              type="button"
                            >
                              Fill
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="table-responsive  no-pagination table-container">
                        <table className="table tablezindbill">
                          <thead>
                            <tr>
                              <th
                                className="sticky-header"
                                style={{ width: "10px" }}
                              >
                                {" "}
                                sl
                              </th>
                              <th className="sticky-header">BillType</th>
                              <th className="sticky-header">BillNo.</th>
                              <th className="sticky-header">Date</th>
                              <th className="sticky-header">Bill Amount</th>
                              <th className="sticky-header">Paid</th>
                              <th className="sticky-header">Paying</th>
                              <th className="sticky-header">Discount</th>
                              <th className="sticky-header">Balance</th>
                              <th className="sticky-header">Inv.No.</th>
                              <th className="sticky-header">Inv.Date</th>
                            </tr>
                          </thead>
                          <tbody>
                            {watch().items?.map((obj, index) => {
                              return (
                                <tr key={index}>
                                  <td>
                                    <input
                                      type="checkbox"
                                      {...register(`items[${index}].select`)}
                                    />
                                  </td>
                                  <td>
                                    <input
                                      type="text"
                                      className="form-control"
                                      {...register(`items[${index}].billtype`)}
                                      disabled
                                    />
                                  </td>
                                  <td>
                                    <input
                                      type="text"
                                      className="form-control"
                                      {...register(`items[${index}].billno`)}
                                      disabled
                                    />
                                  </td>
                                  <td>
                                    <input
                                      type="text"
                                      className="form-control"
                                      {...register(`items[${index}].date`)}
                                      disabled
                                    />
                                  </td>
                                  <td>
                                    <input
                                      type="text"
                                      className="form-control"
                                      {...register(
                                        `items[${index}].billamount`
                                      )}
                                      disabled
                                    />
                                  </td>
                                  <td>
                                    <input
                                      type="text"
                                      className="form-control"
                                      {...register(`items[${index}].paid`)}
                                      disabled
                                    />
                                  </td>
                                  <td>
                                    <input
                                      type="number"
                                      min={0}
                                      step="any"
                                      className="form-control"
                                      {...register(`items[${index}].paying`, {
                                        validate: {
                                          withinBalance: (value) => {
                                            const balance =
                                              getValues(
                                                `items[${index}].billamount`
                                              ) || 0;
                                            const paid =
                                              getValues(
                                                `items[${index}].paid`
                                              ) || 0;
                                            const discount =
                                              getValues(
                                                `items[${index}].discount`
                                              ) || 0;
                                            const amount =
                                              balance - paid - discount;
                                            return (
                                              (!isNaN(value) &&
                                                value <= amount) ||
                                              `Amount can't be greater than balance `
                                            );
                                          },
                                        },
                                        onBlur: (e) => {
                                          handleAddPaying(
                                            index
                                            // e.target.value
                                          ); // Call your custom function
                                        },
                                      })}
                                    />
                                    {errors?.items?.[index]?.paying && (
                                      <span className="error text-danger text-sm">
                                        {errors.items[index].paying.message}
                                      </span>
                                    )}
                                  </td>
                                  <td>
                                    <input
                                      type="text"
                                      className="form-control"
                                      {...register(`items[${index}].discount`, {
                                        validate: {
                                          withinBalance: (value) => {
                                            const balance =
                                              getValues(
                                                `items[${index}].billamount`
                                              ) || 0;
                                            const paid =
                                              getValues(
                                                `items[${index}].paid`
                                              ) || 0;
                                            const paying =
                                              getValues(
                                                `items[${index}].paying`
                                              ) || 0;
                                            const amount =
                                              balance - paid - paying;
                                            return (
                                              (!isNaN(value) &&
                                                value <= amount) ||
                                              `Amount can't be greater than balance ${amount}`
                                            );
                                          },
                                        },
                                        onBlur: (e) => {
                                          handleAddPaying(
                                            index
                                            // e.target.value
                                          );
                                        },
                                      })}
                                    />
                                    {errors?.items?.[index]?.discount && (
                                      <span className="error text-danger text-sm">
                                        {errors.items[index].discount.message}
                                      </span>
                                    )}
                                  </td>
                                  <td>
                                    <input
                                      type="text"
                                      className="form-control"
                                      {...register(`items[${index}].balance`)}
                                      disabled
                                    />
                                  </td>
                                  <td>
                                    <input
                                      type="text"
                                      className="form-control"
                                      {...register(`items[${index}].invNo`)}
                                      disabled
                                    />
                                  </td>
                                  <td>
                                    <input
                                      type="text"
                                      className="form-control"
                                      {...register(`items[${index}].invdate`)}
                                      disabled
                                    />
                                  </td>
                                </tr>
                              );
                            })}
                            <tr>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td className="text-center border-2">
                                <b>{totalamount || 0}</b>
                              </td>
                              <td className="text-center border-2">
                                <b>{totalpaid || 0}</b>
                              </td>
                              <td className="text-center border-2">
                                <b>{totalpaying || 0}</b>
                              </td>
                              <td className="text-center border-2">
                                <b>{totaldiscount || 0}</b>
                              </td>
                              <td className="text-center border-2">
                                <b>{totalbalance || 0}</b>
                              </td>
                              <td></td>
                              <td></td>
                            </tr>
                          </tbody>
                        </table>
                      </div>

                      <div className="row mt-3">
                        <div className="col-12">
                          <div className="input-blocks">
                            <label className="form-label">Remarks</label>
                            <input
                              type="text"
                              className="form-control"
                              {...register("remark")}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-12 text-end">
                          <button
                            type="button"
                            className="btn btn-cancel add-cancel me-3"
                            onClick={resetmodal}
                          >
                            Reset
                          </button>
                          <button
                            className="btn btn-submit add-sale"
                            type="submit"
                            disabled={fields.length > 0 ? false : true}
                          >
                            Submit
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Billreceiptmodal;
