

import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import ImageWithBasePath from "../../core/img/imagewithbasebath";
import {
  PlusCircle,
  RotateCcw,
  ChevronUp,
  Calendar,
} from "feather-icons-react/build/IconComponents";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import Table from "../../core/pagination/datatable";
import {
  deletePurchase,
  getPurchaseByTransType,
} from "../../services/PurchaseApiService";
import PurchaseOrderModal from "../../components/transactions/modal/PurchaseOrderModal";
import { OverlayTrigger, TabPane, Tooltip } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { setheadertoggle } from "../../redux/toggle";
import { PurchaseClass } from "../../core/json/Purchase";
import dayjs from "dayjs";
import { renderTooltip } from "../../redux/helper";
import { DatePicker, Select, Tabs } from "antd";
import { fetchProduct, fetchSupplier, fetchTax, fetchUnit } from "../../redux/userSetting";
import {
  printReport,
  exportExcel,
  exportPDF,
} from "../../core/utils/reportUtils";
import { fetchBranchSettings } from "../../redux/settingbyBranch";
 
const PurchaseOrder = () => {
  const [activeTab, setActiveTab] = useState("1");
  const [searchText, setSearchText] = useState("");
  const [purchaseData, setPurchaseData] = useState([]);
  const [updateData, setUpdateData] = useState([]);
  const [modalMode, setModalMode] = useState("add");
  const [modalOpen, setModalOpen] = useState(false);
  //
  const [isFilterVisible, setIsFilterVisible] = useState(false);
  const [itemwiseData, setitemwiseData] = useState([]);
  const [filtereditemData, setFiltereditemData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [selectedSupplier, setSelectedSupplier] = useState(null);
  const { togglehead } = useSelector((state) => state.headerToggle);
  const { menuItems } = useSelector((state) => state.amc);
  const [permit, setpermit] = useState();
  const { branch } = useSelector((state) => state.branchlist);
 
  const { userId } = useSelector((state) => state.userauth);
  const dispatch = useDispatch();
 
  const handletogglehead = () => {
    dispatch(setheadertoggle(!togglehead));
  };
 
  const handleFromDateChange = (date) => {
    setFromDate(date);
    setToDate(null);
  };
 
  const handleToDateChange = (date) => {
    setToDate(date);
  };
 
  const handleProductChange = (selectedOption) => {
    setSelectedProduct(selectedOption);
  };
 
  const handleSupplierChange = (selectedOption) => {
    setSelectedSupplier(selectedOption);
  };
  useEffect(() => {
    setpermit(
      menuItems?.menuPermissions?.find((e) => e.menu.name == "Purchase Return")
    );
    dispatch(fetchSupplier());
    dispatch(fetchUnit());
    dispatch(fetchTax());
  }, []);
  useEffect(() => {
    handlePurchaseData();
    dispatch(fetchProduct(branch));
    dispatch(fetchBranchSettings(branch.guid));
  }, [branch]);
  const {
    formatedsupplierList,
    formatedproductList,
  } = useSelector((state) => state.usersetting);
  const { TabPane } = Tabs;
  // Fetch purchase orders data
  const handlePurchaseData = async () => {
    try {
      const response = await getPurchaseByTransType(branch, 0);
      const invoice = Array.isArray(response.data) ? response.data : [];
      setPurchaseData(invoice);
      setFilteredData(invoice);
      let serialCounter = 1;
      const itemwiseData = (invoice) => {
        return invoice.purchaseDetails?.map((item, index) => {
          return {
            key: serialCounter,
            // slno: serialCounter++,
            date: invoice.date,
            entrynumber: invoice.entrynumber,
            supplier: invoice.supplier?.name || "Unknown Supplier",
            supplierid: invoice.supplier?.guid || "Unknown Supplier",
            itemName: item.product?.name || "Unknown Item",
            itemid: item.product?.guid || "Unknown Item",
            quantity: item.quantity,
            rate: item.price,
            total: item.netAmount,
            discountTotal: item.discountAmount,
            tax: item.taxAmount,
            netAmount: invoice.netTotal,
            purchasePaymentType: invoice.purchasePaymentType,
          };
        });
      };
 
      const allItemwiseData = invoice.flatMap((invoice) =>
        itemwiseData(invoice)
      );
      setitemwiseData(allItemwiseData);
      setFiltereditemData(allItemwiseData);
    } catch (error) {
      console.error("Failed to fetch purchase orders", error);
    }
  };
 
  const handleSearch = (e) => {
    setSearchText(e.target.value);
  };
 
  // useEffect(() => {
  //   const modifiedList = purchaseData?.filter((item) => {
  //     return Object.values(item).some((val) =>
  //       String(val).toLowerCase().includes(searchText.toLowerCase())
  //     );
  //   });
  //   setFilteredData(modifiedList);
  // }, [searchText, purchaseData]);
  const paymentType = {
    0: "Cash",
    1: "Card",
    2: "Bank",
    3: "Credit",
    4: "UPI",
  };
 
  function handleFilter() {
    const filteredData = purchaseData?.filter((entry) => {
      const supplierMatch =
        !selectedSupplier || entry.supplier?.guid === selectedSupplier;
      const productMatch =
        !selectedProduct ||
        entry?.purchaseDetails?.some(
          (detail) => detail?.product?.guid === selectedProduct
        );
      const fromDateMatch =
        !fromDate || new Date(entry.date) >= new Date(fromDate);
      const toDateMatch = !toDate || new Date(entry.date) <= new Date(toDate);
 
      const searchTextMatch =
        entry?.date?.toLowerCase().includes(searchText?.toLowerCase()) ||
        entry?.supplier?.name
          ?.toLowerCase()
          .includes(searchText?.toLowerCase()) ||
        dayjs(entry?.invoiceDate)
          .format("YYYY-MM-DD")
          ?.includes(searchText?.toLowerCase()) ||
        entry?.referenceNumber
          ?.toLowerCase()
          .includes(searchText?.toLowerCase()) ||
        (paymentType[entry?.purchasePaymentType]?.toLowerCase() || "").includes(
          searchText?.toLowerCase()
        ) ||
        entry?.entrynumber?.toLowerCase().includes(searchText?.toLowerCase()) ||
        entry?.netTotal?.toString().includes(searchText);
 
      return (
        supplierMatch &&
        productMatch &&
        fromDateMatch &&
        toDateMatch &&
        searchTextMatch
      );
    });
 
    setFilteredData(filteredData);
    handleFilteritem();
  }
  function handleFilteritem() {
    const filteredData = itemwiseData?.filter((entry) => {
      const supplierMatch =
        !selectedSupplier || entry.supplierid === selectedSupplier;
      const productMatch = !selectedProduct || entry.itemid === selectedProduct;
 
      const fromDateMatch =
        !fromDate || new Date(entry.date) >= new Date(fromDate);
      const toDateMatch = !toDate || new Date(entry.date) <= new Date(toDate);
 
      const searchTextMatch =
        entry?.date?.toLowerCase().includes(searchText?.toLowerCase()) ||
        entry?.referenceNumber
          ?.toLowerCase()
          .includes(searchText?.toLowerCase()) ||
        entry?.entrynumber?.toLowerCase().includes(searchText?.toLowerCase()) ||
        entry?.itemName?.toLowerCase().includes(searchText?.toLowerCase()) ||
        entry?.supplier?.toLowerCase().includes(searchText?.toLowerCase()) ||
        entry?.quantity?.toString().includes(searchText) ||
        entry?.rate?.toString().includes(searchText) ||
        entry?.netAmount?.toString().includes(searchText) ||
        entry?.tax?.toString().includes(searchText) ||
        entry?.discountTotal?.toString().includes(searchText) ||
        (paymentType[entry?.purchasePaymentType]?.toLowerCase() || "").includes(
          searchText?.toLowerCase()
        ) ||
        entry?.total?.toString().includes(searchText);
 
      return (
        supplierMatch &&
        productMatch &&
        fromDateMatch &&
        toDateMatch &&
        searchTextMatch
      );
    });
 
    setFiltereditemData(filteredData);
  }
  const columns = [
    {
      title: "Date",
      dataIndex: "date",
      sorter: (a, b) => (dayjs(a.date).isAfter(dayjs(b.date)) ? 1 : -1),
      render: (date) => dayjs(date).format("DD-MM-YY"),
    },
    {
      title: "Number",
      dataIndex: "entrynumber",
      sorter: (a, b) => a.entrynumber.localeCompare(b.entrynumber),
    },
    {
      title: "Supplier",
      dataIndex: "supplier.name",
      sorter: (a, b) => a.supplier?.name?.localeCompare(b.supplier?.name),
      render: (_, record) => record?.supplier?.name,
    },
    {
      title: "Delivery Date",
      dataIndex: "duedate",
      sorter: (a, b) => (dayjs(a.duedate).isAfter(dayjs(b.duedate)) ? 1 : -1),
      render: (date) => dayjs(date).format("DD-MM-YY"),
    },
    {
      title: "Status",
      dataIndex: "purchasePaymentType",
      render: (purchasePaymentType) => {
        let badgeClass = "";
        let text = "";
 
        switch (purchasePaymentType) {
          case 0:
            text = "Cash";
            badgeClass = "bg-lightyellow";
            break;
          case 1:
            text = "Card";
            badgeClass = "bg-lightpurple";
            break;
          case 2:
            text = "Bank";
            badgeClass = "bg-lightblue";
            break;
          case 3:
            text = "Credit";
            badgeClass = "bg-lightred";
            break;
          case 4:
            text = "UPI";
            badgeClass = "bg-lightgreen";
            break;
          default:
            text = "Unknown";
            badgeClass = "";
        }
 
        return <span className={`badges ${badgeClass}`}>{text}</span>;
      },
 
      sorter: (a, b) => a.purchasePaymentType - b.purchasePaymentType,
    },
 
    {
      title: "Net Amount",
      dataIndex: "netTotal",
      sorter: (a, b) => a.netTotal - b.netTotal,
    },
    ...(permit?.edit || permit?.deletion
      ? [
          {
            title: "Actions",
            dataIndex: "actions",
            key: "actions",
            render: (_, obj) => (
              <div className="action-table-data">
                <div className="edit-delete-action">
                  {permit?.edit && (
                    <Link
                      className="me-2 p-2"
                      to="#"
                      onClick={() => handleEditClick(obj.guid)}
                    >
                      <i data-feather="edit" className="feather-edit"></i>
                    </Link>
                  )}
                  {permit?.deletion && (
                    <Link
                      className="confirm-text p-2"
                      to="#"
                      onClick={() => handleDeleteClick(obj)}
                    >
                      <i data-feather="trash-2" className="feather-trash-2"></i>
                    </Link>
                  )}
                </div>
              </div>
            ),
          },
        ]
      : []),
  ];
  const Reportcolumns = [
    // {
    //   title: "Sl No.",
    //   dataIndex: "no",
    //   render: (text, record, index) => index + 1,
    // },
    {
      title: "Date",
      dataIndex: "date",
      sorter: (a, b) => (dayjs(a.date).isAfter(dayjs(b.date)) ? 1 : -1),
      render: (date) => dayjs(date).format("DD-MM-YY"),
    },
    {
      title: "Bill No",
      dataIndex: "entrynumber",
      sorter: (a, b) => a.entrynumber.localeCompare(b.entrynumber),
    },
    {
      title: "Supplier",
      dataIndex: "supplier.name",
      sorter: (a, b) => a.supplier?.name?.length - b.supplier?.name?.length,
      render: (_, record) => record?.supplier?.name,
    },
    {
      title: "Total",
      dataIndex: "grossTotal",
      sorter: (a, b) => a.grossTotal - b.grossTotal,
    },
    {
      title: "Discount",
      dataIndex: "discountTotal",
      sorter: (a, b) => a.discountTotal - b.discountTotal,
    },
    {
      title: "Tax",
      dataIndex: "taxTotal",
      sorter: (a, b) => a.taxTotal - b.taxTotal,
    },
    {
      title: "Net",
      dataIndex: "netTotal",
      sorter: (a, b) => a.netTotal - b.netTotal,
    },
  ];
  const supplierColumns = [
    // {
    //   title: "Sl No.",
    //   dataIndex: "no",
 
    //   render: (text, record, index) => index + 1,
    // },
    {
      title: "Date",
      dataIndex: "date",
      sorter: (a, b) => (dayjs(a.date).isAfter(dayjs(b.date)) ? 1 : -1),
      render: (date) => dayjs(date).format("DD-MM-YY"),
    },
    {
      title: "Bill No",
      dataIndex: "entrynumber",
      sorter: (a, b) => a.entrynumber.localeCompare(b.entrynumber),
    },
    {
      title: "Supplier",
      dataIndex: "supplier.name",
      sorter: (a, b) => a.supplier?.name?.length - b.supplier?.name?.length,
      render: (_, record) => record?.supplier?.name,
    },
    {
      title: "Invoice No.",
      dataIndex: "invoiceNo",
      sorter: (a, b) => a.invoiceNo?.length - b.invoiceNo?.length,
    },
    {
      title: "Invoice Date",
      dataIndex: "invoiceDate",
      sorter: (a, b) =>
        dayjs(a.invoiceDate).isAfter(dayjs(b.invoiceDate)) ? 1 : -1,
      render: (date) => dayjs(date).format("DD-MM-YY"),
    },
    {
      title: "Total",
      dataIndex: "grossTotal",
      sorter: (a, b) => a.grossTotal - b.grossTotal,
    },
    {
      title: "Discount",
      dataIndex: "discountTotal",
      sorter: (a, b) => a.discountTotal - b.discountTotal,
    },
    {
      title: "Tax",
      dataIndex: "taxTotal",
      sorter: (a, b) => a.taxTotal - b.taxTotal,
    },
    {
      title: "Net",
      dataIndex: "netTotal",
      sorter: (a, b) => a.netTotal - b.netTotal,
    },
  ];
 
  const Itemwisecolumns = [
    // {
    //   title: "Sl No.",
    //   dataIndex: "slno",
    // },
    {
      title: "Date",
      dataIndex: "date",
      sorter: (a, b) => (dayjs(a.date).isAfter(dayjs(b.date)) ? 1 : -1),
      render: (date) => dayjs(date).format("DD-MM-YY"),
    },
    {
      title: "Bill No",
      dataIndex: "entrynumber",
      sorter: (a, b) => a.entryNumber - b.entryNumber,
    },
    {
      title: "Supplier",
      dataIndex: "supplier",
      sorter: (a, b) => a.supplier?.length - b.supplier?.length,
    },
    {
      title: "Item Name",
      dataIndex: "itemName",
      sorter: (a, b) => a.itemName?.length - b.itemName?.length,
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
      sorter: (a, b) => a.quantity - b.quantity,
    },
    {
      title: "Rate",
      dataIndex: "rate",
      sorter: (a, b) => a.rate - b.rate,
    },
    {
      title: "Total",
      dataIndex: "total",
      sorter: (a, b) => a.netAmount - b.netAmount,
    },
    {
      title: "Discount",
      dataIndex: "discountTotal",
      sorter: (a, b) => a.discountTotal - b.discountTotal,
    },
    {
      title: "Tax",
      dataIndex: "tax",
      sorter: (a, b) => a.tax - b.tax,
    },
    {
      title: "Net",
      dataIndex: "netAmount",
      sorter: (a, b) => a.netAmount - b.netAmount,
    },
  ];
  const MySwal = withReactContent(Swal);
 
  // Handle the click event to delete a Purchase Invoice
  const handleDeleteClick = (obj) => {
    MySwal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#00ff00",
      cancelButtonColor: "#ff0000",
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "Cancel",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const objPurchase = new PurchaseClass();
          objPurchase.id = obj.id;
          objPurchase.guid = obj.guid;
          objPurchase.branch = branch;
          objPurchase.isDeleted = true;
          objPurchase.updatedUser = `{${userId?.id}}`;
          const response = await deletePurchase(objPurchase);
          if (response) {
            MySwal.fire({
              title: "Deleted!",
              text: "The Order has been deleted.",
              icon: "success",
              confirmButtonColor: "#00ff00",
              confirmButtonText: "OK",
            }).then(() => {
              handlePurchaseData(); // Refresh the list
              setSearchText(""); // Clear the search text
            });
          } else {
            MySwal.fire({
              title: "Error!",
              text: "Something went wrong.",
              icon: "error",
              confirmButtonColor: "#ff0000",
              confirmButtonText: "OK",
            });
          }
        } catch (error) {
          console.error("Error deleting reason:", error);
          MySwal.fire({
            title: "Error!",
            text: "Something went wrong.",
            icon: "error",
            confirmButtonColor: "#ff0000",
            confirmButtonText: "OK",
          });
        }
      }
    });
  };
 
  const handleEditClick = async (guid) => {
    try {
      const response = getPurchaseInvoiceByGuid(guid);
 
      setUpdateData(response);
      setModalMode("edit");
 
      setModalOpen(true);
    } catch (error) {
      console.error("Error fetching purchase order by GUID", error);
    }
  };
 
  function getPurchaseInvoiceByGuid(guid) {
    const filteredPurchase = purchaseData?.filter(
      (purchase) => purchase.guid === guid
    );
 
    return filteredPurchase[0];
  }
  const handleTabChange = (key) => {
    setActiveTab(key);
  };
  const getActiveTabData = () => {
    let fieldMapping = {};
 
    // Function to create a column mapping (column title to dataIndex)
    const createfieldMapping = (columns) => {
      const mappings = {};
      columns.forEach((col) => {
        mappings[col.title] = col.dataIndex; // Map column title to its dataIndex
      });
      return mappings;
    };
    switch (activeTab) {
      case "1":
        fieldMapping = createfieldMapping(columns);
 
        return { data: filteredData, columns: columns, fieldMapping };
      case "2":
        fieldMapping = createfieldMapping(Reportcolumns);
 
        return { data: filteredData, columns: Reportcolumns, fieldMapping };
      case "3":
        fieldMapping = createfieldMapping(supplierColumns);
 
        return { data: filteredData, columns: supplierColumns, fieldMapping };
      case "4":
        fieldMapping = createfieldMapping(Itemwisecolumns);
 
        return { data: itemwiseData, columns: Itemwisecolumns, fieldMapping };
      default:
        return { data: [], columns: [] };
    }
  };
  function handleReset() {
    setFromDate(null);
    setToDate(null);
    setSelectedProduct(null);
    setSelectedSupplier(null);
    setSearchText("");
    setFiltereditemData(itemwiseData);
    setFilteredData(purchaseData);
  }
 
  return (
    <div>
      <div className="page-wrapper">
        <div className="content">
          <div className="page-header">
            <div className="add-item d-flex">
              <div className="page-title">
                <h4>Purchase Order</h4>
                <h6>Manage purchase orders here</h6>
              </div>
            </div>
            <ul className="table-top-head">
              <li>
                <OverlayTrigger placement="top" overlay={renderTooltip("PDF")}>
                  <Link onClick={() => exportPDF(getActiveTabData,"Purchase Order")}>
                    <ImageWithBasePath
                      src="assets/img/icons/pdf.svg"
                      alt="img"
                    />
                  </Link>
                </OverlayTrigger>
              </li>
              <li>
                <OverlayTrigger
                  placement="top"
                  overlay={renderTooltip("Excel")}
                >
                  <Link onClick={() => exportExcel(getActiveTabData,"Purchase Order")}>
                    <ImageWithBasePath
                      src="assets/img/icons/excel.svg"
                      alt="img"
                    />
                  </Link>
                </OverlayTrigger>
              </li>
              <li>
                <OverlayTrigger
                  placement="top"
                  overlay={renderTooltip("Print")}
                >
                  <Link onClick={() => printReport(getActiveTabData,"Purchase Order")}>
                    <i data-feather="printer" className="feather-printer" />
                  </Link>
                </OverlayTrigger>
              </li>
              <li>
                <OverlayTrigger
                  placement="top"
                  overlay={renderTooltip("Refresh")}
                >
                  <Link
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    onClick={() => handlePurchaseData()}
                  >
                    <RotateCcw />
                  </Link>
                </OverlayTrigger>
              </li>
              <li>
                <OverlayTrigger
                  placement="top"
                  overlay={renderTooltip("Collapse")}
                >
                  <Link
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    id="collapse-header"
                    className={togglehead ? "active" : ""}
                    onClick={handletogglehead}
                  >
                    <ChevronUp />
                  </Link>
                </OverlayTrigger>
              </li>
            </ul>
            {permit?.add && (
              <div className="page-btn">
                <Link
                  to="#"
                  className="btn btn-added"
                  onClick={() => {
                    setModalMode("add"); // Set the modal mode to 'add'
                    setModalOpen(true); // Open the modal
                  }}
                >
                  <PlusCircle className="me-2" />
                  New
                </Link>
              </div>
            )}
          </div>
 
          <div className="card table-list-card">
            <div className="card-body">
              {/* <div className="table-top">
                <div className="search-set">
                  <div className="search-input">
                    <input
                      type="text"
                      placeholder="Search"
                      className="form-control form-control-sm formsearch"
                      aria-controls="DataTables_Table_0"
                      value={searchText}
                      onChange={handleSearch}
                    />
                    <Link to className="btn btn-searchset btn-sm rounded-2">
                      <i data-feather="search" className="feather-search" />
                    </Link>
                  </div>
                </div>
 
                <div className="d-flex justify-content-end">
                  {/* {isFilterVisible && ( */}
                  {/* <div className="input-blocks me-2">
                    <button
                      className="btn btn-filters  h-100 rounded-2 "
                      onClick={handleFilter}
                    >
                      <i data-feather="search" className="feather-search" />{" "}
                      Search
                    </button>
                  </div>
                  {/* )} */}
 
                  {/* <div className="input-blocks">
                    <button
                      className="btn btn-reset  rounded-2 "
                      onClick={handleReset}
                    >
                      <i
                        data-feather="refresh-cw"
                        className="feather-refresh-cw"
                      />{" "}
                      Reset
                    </button>
                  </div>
                </div>
              </div> */}
 
              {/* <div
                className={`${isFilterVisible ? " visible" : ""}`}
                id="filter_inputs"
                style={{ display: isFilterVisible ? "block" : "none" }}
              > */}
              {/* <div className="card-body">
                <div className="row">
                  {/* From Date Filter */}
                  {/* <div className="col-lg-2 col-sm-6 col-12">
                    <div className="input-blocks ms-4">
                      <label>From Date</label>
                      <div className="input-groupicon ">
                        <Calendar className="info-img" />
                        <DatePicker
                          className="datetimepicker"
                          id="from-date"
                          placeholderText="From Date"
                          value={fromDate}
                          onChange={handleFromDateChange}
                          startDate={fromDate}
                          selectsStart
                          dateFormat="dd/MM/yyyy"
                          style={{ height: "38px", width: "100%" }} // Inline styles for height and width
                        />
                      </div>
                    </div>
                  </div>  */}
 
                  {/* To Date Filter */}
                  {/* <div className="col-lg-2 col-sm-6 col-12">
                    <div className="input-blocks">
                      <label>To Date</label>
                      <div className="input-groupicon ">
                        <Calendar className="info-img" />
                        <DatePicker
                          id="to-date"
                          placeholderText="To Date"
                          value={toDate}
                          onChange={handleToDateChange}
                          minDate={fromDate}
                          startDate={fromDate}
                          selectsEnd
                          disabled={!fromDate}
                          dateFormat="dd/MM/yyyy"
                          style={{ height: "38px", width: "100%" }} // Inline styles for height and width
                        />
                      </div>
                    </div>
                  </div> */}
                  {/* <div className="col-lg-3 col-sm-6 col-12">
                    <div className="input-blocks">
                      <label>Product</label>
 
                      <div className="input-groupicon ">
                        <Select
                          className="img-select"
                          options={formatedproductList}
                          value={selectedProduct}
                          onChange={handleProductChange}
                          classNamePrefix="react-select"
                          placeholder="Products"
                          style={{ height: "38px", width: "100%" }}
                        />
                      </div>
                    </div> */}
                  {/* </div>
                  <div className="col-lg-2 col-sm-6 col-12">
                    <div className="input-blocks">
                      <label>Supplier</label>
 
                      <div className="input-groupicon ">
                        <Select
                          className="img-select"
                          options={formatedsupplierList}
                          value={selectedSupplier}
                          onChange={handleSupplierChange}
                          classNamePrefix="react-select"
                          placeholder="Suppliers"
                          style={{ height: "38px", width: "100%" }}
                        />
                      </div>
                    </div>
                  </div> */}
                {/* </div>
              </div>   */}
              <div className="row align-items-center m-2">
  {/* Search Input */}
  <div className="col-auto mt-3">
    <input
      type="text"
      placeholder="Search"
      className="form-control form-control-sm "
      value={searchText}
      onChange={handleSearch}
      style={{ height: "38px", width: "100%" }}
    />
  </div>
 
  {/* From Date */}
  <div className="col-auto mt-3">
  <div className="input-blocks ms-4">
                      <label>From Date</label>
                      <div className="input-groupicon ">
                        <Calendar className="info-img" />
                        <DatePicker
                          className="datetimepicker"
                          id="from-date"
                          placeholderText="From Date"
                          value={fromDate}
                          onChange={handleFromDateChange}
                          startDate={fromDate}
                          selectsStart
                          dateFormat="dd/MM/yyyy"
                          style={{ height: "38px", width: "100%" }} // Inline styles for height and width
                        />
                      </div>
                      </div>
  </div>
 
  {/* To Date */}
  <div className="col-auto mt-3">
  <div className="input-blocks">
                      <label>To Date</label>
                      <div className="input-groupicon ">
                        <Calendar className="info-img" />
                        <DatePicker
                          id="to-date"
                          placeholderText="To Date"
                          value={toDate}
                          onChange={handleToDateChange}
                          minDate={fromDate}
                          startDate={fromDate}
                          selectsEnd
                          disabled={!fromDate}
                          dateFormat="dd/MM/yyyy"
                          style={{ height: "38px", width: "100%" }} // Inline styles for height and width
                        />
                      </div>
                    </div>
  </div>
 
  {/* Product Dropdown */}
  <div className="col-auto mt-3">
  <div className="input-blocks">
                      <label>Product</label>
 
                      <div className="input-groupicon ">
                        <Select
                          className="img-select"
                          options={formatedproductList}
                          value={selectedProduct}
                          onChange={handleProductChange}
                          classNamePrefix="react-select"
                          placeholder="Products"
                          style={{ height: "38px", width: "100%" }}
                        />
                      </div>
                    </div>
  </div>
 
  {/* Supplier Dropdown */}
  <div className="col-auto mt-3">
  <div className="input-blocks">
                      <label>Supplier</label>
 
                      <div className="input-groupicon ">
                        <Select
                          className="img-select"
                          options={formatedsupplierList}
                          value={selectedSupplier}
                          onChange={handleSupplierChange}
                          classNamePrefix="react-select"
                          placeholder="Suppliers"
                          style={{ height: "38px", width: "100%" }}
                        />
                      </div>
                    </div>
  </div>
 
  {/* Search Button */}
  <div className="col-auto mt-3">
    <button className="btn btn-primary" onClick={handleFilter}>
      Search
    </button>
  </div>
 
  {/* Reset Button */}
  <div className="col-auto mt-3">
    <button className="btn btn-secondary" onClick={handleReset}>
      Reset
    </button>
  </div>
</div>
              {/* </div> */}
              <div className="table-responsive">
                <Tabs defaultActiveKey="1" onChange={handleTabChange}>
                  <TabPane tab="Purchase Order" key="1">
                    <Table columns={columns} dataSource={filteredData} />
                  </TabPane>
                  <TabPane tab="Detailed Reports" key="2">
                    <Table columns={Reportcolumns} dataSource={filteredData} />
                  </TabPane>
                  <TabPane tab="Supplier Reports" key="3">
                    <Table
                      columns={supplierColumns}
                      dataSource={filteredData}
                    />
                  </TabPane>
                  <TabPane tab="Itemwise Reports" key="4">
                    <Table
                      columns={Itemwisecolumns}
                      dataSource={filtereditemData}
                    />
                  </TabPane>
                </Tabs>
              </div>
            </div>
          </div>
        </div>
      </div>
 
      {modalOpen && (
        <PurchaseOrderModal
          mode={modalMode}
          data={modalMode === "edit" ? updateData : {}}
          handleClose={() => setModalOpen(false)}
          handleRefresh={() => {
            handlePurchaseData();
            setSearchText("");
          }}
        />
      )}
    </div>
  );
};
 
export default PurchaseOrder;